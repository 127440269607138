<template>
  <b-card
    class="card-custom gutter-b"
    no-body
  >
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <b-card-title>
        <h2 class="card-label">
          {{ trans('nav.aside.templates.all') }}
          <span class="d-block text-muted pt-2 font-size-sm">
            {{ trans('templates.index.desc') }}
          </span>
        </h2>
      </b-card-title>
      <!-- card-toolbar placeholder: add toolbar actions here :: <div class="card-toolbar">-->
    </div>
    <div class="card-body" style="min-height: 80px;">
      <b-table
        id="template-table"
        primary-key="templateid"
        :fields="fields"
        :items="items"
        responsive="md"
      >
        <!--begin::template-image-->
        <template
          v-if="tableProps.loading"
          v-slot:cell(image)
          class="template-image"
        >
          <div class="symbol symbol-50 symbol-sm flex-shrink-0">
            <div class="symbol-label">
              <div class="list-item loading rounded"></div>
            </div>
          </div>
        </template>

        <template
          v-else
          v-slot:cell(image)="data"
          class="template-image"
        >
          <div class="symbol symbol-60 symbol-sm flex-shrink-0">
            <div class="symbol-label">
              <img
                v-if="data.item.image.type === 'img'"
                class=""
                :src="data.item.image.src"
                alt="Product-image"
              />
              <div
                v-else
                class="svg-icon svg-icon-2x svg-icon-primary-light"
              >
                <inline-svg
                  :src="data.item.image.src"
                ></inline-svg>
              </div>
            </div>
          </div>
        </template>
        <!--end::template-image-->

        <!--begin::template-name-->
        <template
          v-if="tableProps.loading"
          v-slot:cell(name)
          class="template-name"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(name)="data"
          class="template-name"
        >
          {{ data.item.name }}
        </template>
        <!--end::template-name-->

        <!--begin::template-customer-->
        <template
          v-if="tableProps.loading"
          v-slot:cell(customer)
          class="template-customer"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(customer)="data"
          class="template-customer"
        >
          <span
            v-if="data.item.customerExtId === 0"
            class="label label-lg font-weight-bold label-inline label-light-success"
          >
            {{ data.item.customer }}
          </span>
          <span
            v-else
            class="label label-lg font-weight-bold label-inline label-light-primary"
          >
            {{ data.item.customer }}
          </span>
        </template>
        <!--end::template-customer-->

        <!--begin::template-created-->
        <template
          v-if="tableProps.loading"
          v-slot:cell(created)
          class="template-created"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(created)="data"
          class="template-created"
        >
          {{ data.item.created }}
        </template>
        <!--end::template-created-->

        <!--begin::actions-->
        <template
          v-if="tableProps.loading"
          v-slot:cell(actions)
          class="template-actions"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(actions)="data"
        >
          <b-button
            :ref="'template-view-' + data.item.id"
            variant="light-primary"
            size="sm"
            class="btn-icon btn-icon-primary"
            :to="`/templates/edit/${ data.item.id }`"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg :src="'/media/svg/icons/Design/Edit.svg'" />
            </span>
          </b-button>

          <b-button
            :ref="'styletype-view-' + data.item.id"
            variant="light-danger"
            size="sm"
            class="btn-icon btn-icon-danger ml-2"
            @click="openModal(data.item.id, 'delete')"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg :src="'/media/svg/icons/Home/Trash.svg'" />
            </span>
          </b-button>
        </template>
        <!--end::actions-->
      </b-table>

      <!--begin::more-btn-->
      <div class="d-flex justify-content-center align-items-center flex-wrap">
        <div class="d-flex align-items-center py-3">
          <div class="d-flex align-items-center">
            <Loader
              v-if="tableProps.loading"
              :text="trans('templates.index.table.btn.loading')"
              loader-class="relative"
              loader-style="margin-right: 80px;"
            ></Loader>
            <b-btn
              v-else-if="tableProps.showMoreBtn"
              ref="templates_showmorebtn"
              variant="primary"
              class="py-4 px-6"
              size="sm"
              @click="loadMore(tableProps.lastVisibleDoc)"
            >
              {{ trans('templates.index.table.btn.title') }}
            </b-btn>
          </div>
        </div>
      </div>
      <!--end::more-btn-->
      <!--begin::Modals-->
      <ModalDeleteTemplate
        :template-data="templateData"
      ></ModalDeleteTemplate>
      <!--end::Modals-->
    </div>
  </b-card>
</template>


<script>
  import { SETTINGS } from '@src/store/settings'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

  import * as objectPath from 'object-path'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { ref, computed, onBeforeMount, onMounted } from '@vue/composition-api'
  import useDateTime from '@src/use/datetime'
  import { capitalize, truncate } from '@src/core/helpers/textUtils'

  import ModalDeleteTemplate from '@src/components/modals/templates/Delete'

  export default {
    name: 'TemplatesIndex',
    components: {
      Loader: () => import('@src/components/content/Loader'),
      ModalDeleteTemplate
    },
    setup (props, { root, refs }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const templates = ref({})
      const templateData = ref({})
      const { momentFullDate, momentHoursMinutesSeconds } = useDateTime()

      onBeforeMount(() => {
        root.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'Dashboard')
      })

      const tableProps = ref({
        limit: 10,
        loading: true,
        orderBy: 'createdOn',
        sortOrder: 'desc',
        lastVisibleDoc: true,
        showMoreBtn: true
      })

      const tableItems = ref(new Array(tableProps.value.limit).fill({ }))

      const items = computed(() => tableItems.value.map((template) => {
        const { id, customerExtId } = template
        const templateImages = objectPath.get(template, 'image', {})
        const currentTemplateImageKey = !isNaN(parseInt(Object.keys(templateImages).pop(), 10))
          ? parseInt(Object.keys(templateImages).pop(), 10)
          : undefined

        const image = currentTemplateImageKey !== undefined
          ? { type: 'img', src: `${process.env.VUE_APP_IMAGE_CDN_URL}/${ templateImages[currentTemplateImageKey].path }/${ templateImages[currentTemplateImageKey].file }?fit=crop&w=60&h=60` } // eslint-disable-line max-len, object-property-newline
          : { type: 'svg', src: '/media/svg/icons/Design/Image.svg' } // eslint-disable-line object-property-newline

        const name = truncate(objectPath.get(template, 'name', `(${ trans('templates.index.table.name.missing') })`), 60) // eslint-disable-line max-len
        const created = `${momentFullDate(objectPath.get(template, 'createdOn', new Date()))} @ ${momentHoursMinutesSeconds(objectPath.get(template, 'createdOn', new Date()))}` // eslint-disable-line max-len

        // Customer data
        let customer = ''
        const externalCustomerId = objectPath.get(template, 'customerExtId', 0)

        if (externalCustomerId > 0) {
          customer = objectPath.get(template, '_customer.name', `(${ trans('templates.index.table.customer.missing') })`) // eslint-disable-line max-len
        } else {
          customer = trans('templates.index.table.customer.all')
        }

        return {
          id,
          image,
          name,
          customer,
          created,
          customerExtId
        }
      }))

      const fields = computed(() => [
        {
          key: 'image',
          label: capitalize(transChoice('global.image', 0)),
          sortable: false,
          tdClass: 'template-image',
          thClass: 'template-image-head'
        },
        {
          key: 'name',
          label: capitalize(transChoice('global.name', 0)),
          sortable: false,
          tdClass: 'template-name',
          thClass: 'template-name-head'
        },
        {
          key: 'customer',
          label: capitalize(transChoice('global.access', 0)),
          sortable: false,
          tdClass: 'template-customer',
          thClass: 'template-customer-head'
        },
        {
          key: 'created',
          label: capitalize(transChoice('global.created', 1)),
          sortable: true,
          tdClass: 'template-createdon',
          thClass: 'template-createdon-head'
        },
        {
          key: 'actions',
          label: capitalize(transChoice('global.action', 2)),
          sortable: false,
          tdClass: 'template-actions',
          thClass: 'template-actions-head'
        }
      ])

      const loadMore = async (paginateDoc) => {
        const btn = refs.templates_showmorebtn
        if (btn !== undefined) btn.classList.add('spinner', 'spinner-light', 'spinner-right', 'pr-14')

        const getTemplates = fb.functions.httpsCallable('backendTemplatesGetTemplates')

        return await getTemplates({
          limit: tableProps.value.limit,
          orderBy: tableProps.value.orderBy,
          sort: tableProps.value.sortOrder,
          paginateDoc
        }).then(async (res) => {
          console.log('RESULT: ', res)
          if (objectPath.get(res, 'data.errors', false)) {
            tableProps.value.loading = false
            tableProps.value.showMoreBtn = false
            tableItems.value = []
            return
          }

          if (tableProps.value.loading) tableItems.value = [] // Remove placeholders
          tableProps.value.showMoreBtn = Object.keys(objectPath.get(res, 'data.result', {})).length >= tableProps.value.limit // eslint-disable-line max-len

          const getCustomer = fb.functions.httpsCallable('backendCustomersGetCustomerById')

          // eslint-disable-next-line guard-for-in
          for (const docKey in objectPath.get(res, 'data.result', {})) {
            const docData = res.data.result[docKey]
            if (objectPath.get(docData, 'customerExtId', 0) > 0) {
              // eslint-disable-next-line no-await-in-loop
              await getCustomer({
                id: objectPath.get(docData, 'customerId', false)
              }).then((cRes) => {
                docData._customer = objectPath.get(cRes, 'data.result', {})
              }).catch((err) => {
                console.log('ERROR while fetching customer', err)
              })
            }
            tableItems.value.push(docData)
            templates.value[objectPath.get(docData, 'id', null)] = docData
          }

          if (btn !== undefined) btn.classList.remove('spinner', 'spinner-light', 'spinner-right', 'pr-14')
          tableProps.value.lastVisibleDoc = objectPath.get(res, 'data.paginateDoc.lastVisibleDoc', true)
        })
      }

      // Vue 2 "created" Vue trigger:
      loadMore(tableProps.value.lastVisibleDoc).then(() => {
        tableProps.value.loading = false
      })

      onMounted(() => {
        root.$store.dispatch(SET_BREADCRUMB, [
          {
            title: trans('nav.aside.templates.title'),
            route: 'users'
          },
          { title: trans('nav.aside.templates.all') }
        ])

        root.$on('bv::modal::hide', (bvEvent, modalId) => {
          if (modalId === 'modal-template-delete') {
            tableProps.value.loading = true
            loadMore(tableProps.value.lastVisibleDoc).then(() => {
              tableProps.value.loading = false
            })
          }
        })
      })

      const openModal = async (tId, modal) => {
        templateData.value = templates.value[tId]
        await new Promise((resolve) => setTimeout(resolve, 200)) // Populate the styletypeData
        root.$bvModal.show(`modal-template-${ modal }`)
      }

      return {
        trans,
        transChoice,
        truncate,
        items,
        fields,
        tableProps,
        templateData,
        loadMore,
        openModal
      }
    }
  }
</script>

<style lang="scss">
  .table td {
    &.template-createdon, &.template-customer, &.template-name, &.template-actions {
      vertical-align: middle;
    }
  }
  .template-image-head {
    width: 120px;
  }

  .list-item {
    overflow: hidden;

    &.loading {
      height: 1.8rem;
    }
  }
</style>
